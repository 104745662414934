import { useLazyQuery, useMutation } from '@apollo/client';
import { FC, Suspense, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
	IRemoveItemFromCartMutationResponse,
	REMOVE_ITEM_FROM_CART_MUTATION,
} from '../../graphql/mutations/cart';
import { ROUTER_PATH } from '../../router/routes';
import { formatNumber2Price } from '../../utils/price';
import { Button, BUTTON_WIDTH } from '../Buttons/Buttons';
import { Modal } from '../Modal/Modal';
import { Spinner } from '../Spinner/Spinner';
import { COLORS } from '../styleguide/colors';
import {
	MVTHeadline2,
	PriceView,
	TypoHeadline2,
} from '../styleguide/typography';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { useTranslation } from 'react-i18next';
import { LANG } from '../../models/ILang';
import { isDongleProduct } from '../../utils/helpers';
import { useCheckSession } from '../../hooks/useCheckSession';
import {
	IGetCustomerCartQueryResponse,
	GET_CUSTOMER_CART,
} from '../../graphql/queries/cart';
import { AppContext } from '../../App';
import { ContainerView } from '../Container/Container.view';

const ShoppingCartWrapperView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 35px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		flex-direction: column-reverse;
	}
`;

const ShoppingCartButtonContainerView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const ShoppingCartContainerView = styled.div`
	display: grid;

	.shopping-cart {
		&__totalCartPrice {
			display: flex;
			flex-direction: column;
			gap: 10px;
			word-break: keep-all;
		}

		&__group {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			gap: 35px;
		}

		&__item {
			display: flex;
			gap: 15px;

			&-info-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				flex: 1;

				ul {
					padding-left: 20px;
					font-family: Arial, Helvetica, sans-serif;
					font-size: 16px;

					@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
						font-family: Futura;
					}
				}
			}

			&-image {
				flex: 0.7;
				display: flex;
				justify-content: center;
				align-items: center;

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					padding: 20px;
				}

				img {
					max-height: 175px;
					max-width: 100%;
				}

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					background-color: ${COLORS.BG_GrayColor};
				}
			}

			&-name {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 15px;

				& > svg {
					min-width: 24px;
					cursor: pointer;
				}
			}
		}
	}
`;

const ShoppingCartTotalPriceContainerView = styled.div`
	display: flex;
	justify-content: flex-end;
	column-gap: 10px;
	flex-wrap: wrap;
	text-align: right;

	& > div > span {
		text-transform: capitalize;
	}
`;

interface IShoppingCartProps {
	opened: boolean;
}

export const ShoppingCart: FC<IShoppingCartProps> = ({ opened }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isLoadingBillingPage, setIsLoadingBillingPage] =
		useState<boolean>(false);

	const [errorMessage, setErrorMessage] = useState('');

	const { isLogged, setOpenedCart } = useContext(AppContext);

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const navigate = useNavigate();

	const location = useLocation();

	const checkSession = useCheckSession();

	const [getCustomerCart, cart] = useLazyQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted() {
				setIsLoading(false);
			},
			onError(error) {
				console.error('cart GET_CUSTOMER_CART error:>>', error);

				setIsLoading(false);
			},
		},
	);

	useEffect(() => {
		isLogged && getCustomerCart();
	}, [isLogged]);

	const [removeItemFromCartMutation] =
		useMutation<IRemoveItemFromCartMutationResponse>(
			REMOVE_ITEM_FROM_CART_MUTATION,
		);

	const isCheckoutProcess = (): boolean =>
		location.pathname.endsWith(ROUTER_PATH.billing) ||
		location.pathname.endsWith(ROUTER_PATH.overview);

	const handleDelete = (cartItemUid: string): void => {
		setIsLoading(true);

		const removeItem = (): void => {
			removeItemFromCartMutation({
				variables: {
					cartId: cart.data?.customerCart.id,
					cartItemUid,
				},
				refetchQueries: [{ query: GET_CUSTOMER_CART }],
				onCompleted(data) {
					const response = data.removeItemFromCart.cart;
					if (!response.items.length && isCheckoutProcess()) {
						navigate(current_lang + ROUTER_PATH.products);
						setOpenedCart(false);
					}
					setIsLoading(false);
				},
				onError(error) {
					console.error('removeItemFromCartMutation error:>>', error);
					setIsLoading(false);
					setErrorMessage(
						t('shopping_cart_deleting_dongle_warning') ??
							'In Ihrer Auswahl befindet sich ein Produkt inklusive Dongle. Sollten Sie den Dongle nicht benötigen, löschen Sie bitte das zugehörige Softwareprodukt und fügen Sie es erneut ohne Dongle hinzu.',
					);
				},
			});
		};

		checkSession(removeItem, {
			expiredSessionCallback: () => {
				setOpenedCart(false);
				setIsLoading(false);
			},
		});
	};

	const handlePurchaseButton = (): void => {
		setIsLoadingBillingPage(true);

		const navigate2Billing = (): void => {
			setIsLoadingBillingPage(false);

			setOpenedCart(false);

			navigate(current_lang + ROUTER_PATH.billing);
		};

		checkSession(navigate2Billing, {
			expiredSessionCallback: () => {
				setIsLoading(false);

				setOpenedCart(false);
			},
		});
	};

	return (
		<Modal
			show={opened}
			onClose={() => setOpenedCart(false)}
			isShoppingCart
			style={{
				modalLayout: {
					padding: 0,
				},
				modalContainer: {
					marginRight: 0,
					marginTop: screen.width < GRID_BREAKPOINTS.$sm ? 65 : 0,
					marginBottom: 0,
					minHeight:
						screen.width < GRID_BREAKPOINTS.$sm
							? 'calc(100vh - 65px)'
							: '100vh',
					borderRadius: 0,
				},
			}}
		>
			<ContainerView>
				<Suspense fallback={<Spinner />}>
					<ShoppingCartWrapperView>
						<ShoppingCartContainerView className="shopping-cart">
							{cart.loading || isLoading ? (
								<Spinner />
							) : (
								<>
									<MVTHeadline2>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												columnGap: 15,
												flexWrap: 'wrap',
											}}
										>
											<div>{t('shopping_cart_typo')}</div>

											<ShoppingCartTotalPriceContainerView>
												<div>
													{t(
														'shopping_cart_total_typo',
													)}{' '}
													<span>
														(
														{t(
															'shopping_cart_netto_typo',
														)}
														)
													</span>
												</div>
												<PriceView className="shopping-cart__totalCartPrice">
													{formatNumber2Price(
														cart.data?.customerCart
															.prices
															.subtotal_excluding_tax ?? {
															value: 0,
															currency: 'EUR',
														},
													)}
												</PriceView>
											</ShoppingCartTotalPriceContainerView>
										</div>
									</MVTHeadline2>
									<div className="shopping-cart__group">
										{!!cart.data?.customerCart.items
											.length &&
											cart.data?.customerCart.items.map(
												item => {
													return (
														<div
															key={
																item.product.sku
															}
															className="shopping-cart__item"
														>
															<div className="shopping-cart__item-image">
																<img
																	src={
																		item
																			.product
																			.image
																			.url
																	}
																	alt={
																		item
																			.product
																			.image
																			.label
																	}
																/>
															</div>
															<div className="shopping-cart__item-info-container">
																<div className="shopping-cart__item-name">
																	<TypoHeadline2>
																		{
																			item
																				.product
																				.name
																		}
																	</TypoHeadline2>
																	<DeleteIcon
																		fill={
																			COLORS.primaryColor
																		}
																		onClick={() =>
																			item.uid &&
																			handleDelete(
																				item.uid,
																			)
																		}
																	/>
																</div>
																<ul>
																	<li>
																		{t(
																			'shopping_cart_industrial_typo',
																		)}
																	</li>

																	{!isDongleProduct(
																		item
																			.product
																			.name,
																	) ? (
																		item
																			.additional
																			.host_id
																			.length ? (
																			<li>
																				{t(
																					'shopping_cart_without_dongle_typo',
																				)}
																			</li>
																		) : (
																			<li>
																				{t(
																					'shopping_cart_with_dongle_typo',
																				)}
																			</li>
																		)
																	) : null}
																</ul>

																<div
																	style={{
																		textTransform:
																			'uppercase',
																	}}
																>{`${t(
																	'quantity_typo',
																)} ${
																	item.quantity
																}`}</div>

																<PriceView>
																	{formatNumber2Price(
																		item
																			.prices
																			.row_total,
																	)}
																</PriceView>
															</div>
														</div>
													);
												},
											)}
									</div>
								</>
							)}
						</ShoppingCartContainerView>

						<ShoppingCartButtonContainerView>
							{isLoadingBillingPage ? (
								<Spinner />
							) : (
								<>
									<Button
										primary
										width={BUTTON_WIDTH.full}
										onClick={handlePurchaseButton}
										disabled={
											!cart.data?.customerCart.items
												.length ||
											cart.loading ||
											isLoading
										}
									>
										{`${t('shopping_cart_step_typo')} 1 ${t(
											'shopping_cart_of_typo',
										)} 4`}
									</Button>
									<Button
										secondary
										width={BUTTON_WIDTH.full}
										onClick={() => {
											setOpenedCart(false);

											navigate(
												current_lang +
													ROUTER_PATH.products,
											);
										}}
									>
										{t(
											'shopping_cart_continue_shopping_typo',
										)}
									</Button>
								</>
							)}
						</ShoppingCartButtonContainerView>

						<Modal
							show={!!errorMessage}
							onClose={() => setErrorMessage('')}
						>
							<div style={{ padding: 20 }}>
								<div
									style={{
										margin: '0 auto',
										width: 'fit-content',
										marginBottom: 25,
									}}
								>
									<WarningIcon
										fill={COLORS.secondaryColor}
										width="100px"
										height="100px"
									/>
								</div>
								<TypoHeadline2 style={{ textAlign: 'center' }}>
									{errorMessage}
								</TypoHeadline2>
							</div>
						</Modal>
					</ShoppingCartWrapperView>
				</Suspense>
			</ContainerView>
		</Modal>
	);
};
