import { useLazyQuery, useQuery } from '@apollo/client';
import {
	CSSProperties,
	FC,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext, AppSettingsContext } from '../../App';
import { ContainerView } from '../../components/Container/Container.view';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { Searchbar } from '../../components/Searchbar/Searchbar';
import { Spinner } from '../../components/Spinner/Spinner';
import { COLORS } from '../../components/styleguide/colors';
import { GRID_BREAKPOINTS } from '../../components/styleguide/variables';
import { Switch } from '../../components/Switch/Switch';
import {
	GET_FILTERS_QUERY,
	GET_CATEGORY_LIST_QUERY,
	GET_PRODUCTS_BY_CATEGORIES,
	IGetProductsByCategotiesQueryResponse,
	IGetCategoryListQueryResponse,
	IGetFiltersQueryResponse,
} from '../../graphql/queries/product';
import { IFilter, TFilterValue } from '../../models/IFilter';
import {
	FilterOption,
	ICategory,
	IFilterQueryResponse,
	IProduct,
} from '../../models/IProduct';
import { ROUTER_PATH } from '../../router/routes';
import { MESSAGE_TYPE } from '../MessagePage/MessagePage';
import { useTranslation } from 'react-i18next';
import { LANG } from '../../models/ILang';
import { useCartMutations } from '../../hooks/useCartMutations';
import { ICartItemPayload } from '../../models/ICart';
import { Button } from '../../components/Buttons/Buttons';
import {
	IGetCustomerCartQueryResponse,
	GET_CUSTOMER_CART,
} from '../../graphql/queries/cart';
import {
	MVTHeadline2,
	TypoHeadline2,
	TypoHeadline4,
} from '../../components/styleguide/typography';
import { Modal } from '../../components/Modal/Modal';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { scrollToFirstInvalidControl } from '../../utils/helpers';
import { ProductTile } from './components/ProductTile/ProductTile';
import { ReactComponent as TileViewIcon } from '../../assets/icons/tile-view.svg';
import { ReactComponent as ListViewIcon } from '../../assets/icons/list-view.svg';
import { ProductListItem } from './components/ProductListItem/ProductListItem';
import {
	GET_COMMITMENT_LIST_QUERY,
	IGetCommitmentListQueryResponse,
} from '../../graphql/queries/notifications';
import { IDongleInputError, IDongleProductError } from '../../models/IDongle';

const ProductListPageFiltersContainerView = styled.div`
	display: grid;
	grid-gap: 20px;
	margin-bottom: 50px;

	@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
		& > button {
			width: 300px;
			justify-self: end;
		}
	}

	& > .products__searchbar,
	.products__filters > * {
		width: 100%;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			width: 60%;
		}

		@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
			width: 300px;
		}
	}
`;

const ProductListPageFiltersGroupView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

const ProductListPageCategoryContainerView = styled.div`
	padding-top: 25px;
	display: grid;
	gap: 45px;

	.price-notification {
		color: ${COLORS.textGray};
		font-family: Arial, Helvetica, sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
		/* margin-bottom: 15px; */

		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}
`;

const ProductListPageCategoryNameView = styled.div`
	margin-bottom: 25px;
	width: 100%;
	padding: 5px;
	border-bottom: 2px solid ${COLORS.secondaryColor};
	text-transform: uppercase;
`;

const ProductListPageProductTileGroupView = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;

	@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$md}px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: ${GRID_BREAKPOINTS.$lg}px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`;

const ProductListPageListGroupTableView = styled.table`
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;

	td {
		padding: 15px 20px;
		padding-right: 0;
		vertical-align: top;
	}

	thead > tr {
		& > td:first-child {
			padding-left: 0;
		}

		& > td:last-child {
			display: none;

			@media (min-width: ${GRID_BREAKPOINTS.$smd}px) {
				display: table-cell;
			}
		}
	}

	tbody {
		& > tr {
			& > td:first-child {
				padding-left: 0;
			}
		}

		& > tr:nth-child(odd) {
			border: none;

			& > td:last-child {
				display: none;

				@media (min-width: ${GRID_BREAKPOINTS.$smd}px) {
					display: table-cell;
				}
			}
		}

		& > tr:nth-child(even) {
			& > td {
				@media (min-width: ${GRID_BREAKPOINTS.$smd}px) {
					display: none;
				}
			}
		}
	}

	td:last-child {
		padding-right: 0;
	}
`;

const ProductListPageSwitchListViewGroupView = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(2, min-content);
	justify-content: end;

	& > svg {
		cursor: pointer;
	}
`;

const switchStyles: CSSProperties = {
	width: '',
	justifyContent: 'space-between',
	flexDirection: 'row-reverse',
	fontFamily: 'Futura',
};

enum FILTER_NAMES {
	category = 'category',
	application = 'application',
	version = 'version',
}

export interface IProductsToCartItem extends ICartItemPayload {
	exclude_mac_address: boolean | null;
	product_type: string;
	dongleErrors: IDongleInputError[];
}

type TProductsToCartState = Record<string, IProductsToCartItem>;

export const ProductListPage: FC = () => {
	const { t, i18n } = useTranslation();

	const current_lang = i18n.language as LANG;

	const { navigateToMessagePage } = useContext(AppContext);

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isListView, setIsListView] = useState<boolean>(() => {
		try {
			const savedValue = localStorage.getItem('isListView');
			return savedValue !== null ? JSON.parse(savedValue) : false;
		} catch (error) {
			console.error(
				"Error parsing 'isListView' from localStorage:",
				error,
			);
			return false;
		}
	});

	useEffect(() => {
		try {
			localStorage.setItem('isListView', JSON.stringify(isListView));
		} catch (error) {
			console.error("Error saving 'isListView' to localStorage:", error);
		}
	}, [isListView]);

	const [productsToCart, setProductsToCart] = useState<TProductsToCartState>(
		{},
	);

	const [modalMessages, setModalMessages] = useState<string[]>([]);

	const cartMutations = useCartMutations();

	const [productList, setProductList] = useState<IProduct[]>([]);

	const [categoryFilter, setCategoryFilter] = useState<IFilter<ICategory>>({
		filterValues: [],
		chosenValue: [],
	});

	const [applicationFilter, setApplicationFilter] = useState<
		IFilter<TFilterValue>
	>({
		filterValues: [],
		chosenValue: [],
	});

	const [versionFilter, setVersionFilter] = useState<IFilter<TFilterValue>>({
		filterValues: [],
		chosenValue: [],
	});

	const [searchbarValue, setSearchbarValue] = useState<string>('');

	const appSettings = useContext(AppSettingsContext);

	const commitments = useQuery<IGetCommitmentListQueryResponse>(
		GET_COMMITMENT_LIST_QUERY,
		{
			onError(error) {
				console.error('GET_COMMITMENTS_LIST_QUERY error:>>', error);
			},
			fetchPolicy: 'cache-only',
		},
	);

	const [getProductsByCategoriesQuery] =
		useLazyQuery<IGetProductsByCategotiesQueryResponse>(
			GET_PRODUCTS_BY_CATEGORIES,
			{
				fetchPolicy: 'cache-and-network',
			},
		);

	const [getCategoriesQuery, categoriesQuery] =
		useLazyQuery<IGetCategoryListQueryResponse>(GET_CATEGORY_LIST_QUERY, {
			fetchPolicy: 'cache-and-network',
			onCompleted(data) {
				const allCategories: ICategory[] =
					data.categoryList[0].children.filter(
						item => !!item.products.total_count,
					) ?? [];

				const categSearchParams = searchParams
					.get(FILTER_NAMES.category)
					?.split(',');

				const chosenCategories = allCategories.filter(categ =>
					categSearchParams?.includes(categ.uid),
				);

				setCategoryFilter({
					filterValues: allCategories,
					chosenValue: chosenCategories,
				});
			},
			onError(error) {
				console.error('GET_CATEGORY_LIST_QUERY error:>>', error);

				setIsLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: error.message,
					btnDescr: 'Dashboard',
					redirectUrl: current_lang + ROUTER_PATH.dashboard,
				});
			},
		});

	const [getFiltersQuery, filtersQuery] =
		useLazyQuery<IGetFiltersQueryResponse>(GET_FILTERS_QUERY, {
			fetchPolicy: 'cache-and-network',
			onCompleted(data) {
				const appFilterOptions: FilterOption[] =
					data.customAttributeMetadata.items
						.find(
							(item: IFilterQueryResponse) =>
								item.attribute_code === 'application',
						)
						?.attribute_options.filter(
							item => !!item.products_count,
						) ?? [];

				const appParams = searchParams
					.get(FILTER_NAMES.application)
					?.split(',');

				const chosenApplicationsValues: TFilterValue[] = [];

				appFilterOptions?.map(
					option =>
						appParams?.includes(option.value) &&
						chosenApplicationsValues.push(option),
				);

				const versnFilterOptions: FilterOption[] =
					data.customAttributeMetadata.items
						.find(
							(item: IFilterQueryResponse) =>
								item.attribute_code === 'version',
						)
						?.attribute_options.filter(
							item => !!item.products_count,
						) ?? [];

				const versionParams = searchParams
					.get(FILTER_NAMES.version)
					?.split(',');

				const chosenVersionValues: TFilterValue[] = [];

				versnFilterOptions?.map(
					version =>
						versionParams?.includes(version.value) &&
						chosenVersionValues.push(version),
				);

				setApplicationFilter({
					filterValues: appFilterOptions,
					chosenValue: chosenApplicationsValues,
				});

				setVersionFilter({
					filterValues: versnFilterOptions,
					chosenValue: chosenVersionValues,
				});
			},
			onError(error) {
				console.error(
					'GET_FILTERS_QUERY customAttributeMetadata error:>>',
					error,
				);

				setIsLoading(false);

				navigateToMessagePage(current_lang, {
					type: MESSAGE_TYPE.error,
					title: error.message,
					btnDescr: 'Dashboard',
					redirectUrl: current_lang + ROUTER_PATH.dashboard,
				});
			},
		});

	const cartQuery = useQuery<IGetCustomerCartQueryResponse>(
		GET_CUSTOMER_CART,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted(data) {
				const result =
					data.customerCart.items.reduce<TProductsToCartState>(
						(acc, item) => {
							acc[item.product.sku] = {
								quantity: item.quantity,
								sku: item.product.sku,
								additional: item.additional,
								product_type: item.product.product_type,
								exclude_mac_address:
									item.product.exclude_mac_address,
								dongleErrors: [],
							};

							return acc;
						},
						{},
					);

				setProductsToCart(result);
			},
		},
	);

	useEffect(() => {
		Promise.all([getCategoriesQuery(), getFiltersQuery()]).then(
			([categoriesData, filtersData]) => {
				const allCategories: ICategory[] =
					categoriesData.data?.categoryList[0].children.filter(
						item => !!item.products.total_count,
					) ?? [];

				const appFilterOptions: FilterOption[] =
					filtersData.data?.customAttributeMetadata.items
						.find(
							(item: IFilterQueryResponse) =>
								item.attribute_code === 'application',
						)
						?.attribute_options.filter(
							item => !!item.products_count,
						) ?? [];

				const appParams = searchParams
					.get(FILTER_NAMES.application)
					?.split(',');

				appFilterOptions?.map(option =>
					appParams?.includes(option.value),
				);

				const versnFilterOptions: FilterOption[] =
					filtersData.data?.customAttributeMetadata.items
						.find(
							(item: IFilterQueryResponse) =>
								item.attribute_code === 'version',
						)
						?.attribute_options.filter(
							item => !!item.products_count,
						) ?? [];

				const versionParams = searchParams
					.get(FILTER_NAMES.version)
					?.split(',');

				versnFilterOptions?.map(version =>
					versionParams?.includes(version.value),
				);

				getProductsByCategoriesQuery({
					variables: {
						categoryUids: allCategories.map(item => item.uid),
						applicationUids: appFilterOptions?.map(
							item => item.value,
						),
						versionUids: versnFilterOptions?.map(
							item => item.value,
						),
					},
					fetchPolicy: 'cache-and-network',
					onCompleted(productsData) {
						setProductList(productsData.products.items);

						setIsLoading(false);
					},
					onError(error) {
						console.error(
							'GET_PRODUCTS_BY_CATEGORIES products error:>>',
							error,
						);

						setIsLoading(false);

						navigateToMessagePage(current_lang, {
							type: MESSAGE_TYPE.error,
							title: error.message,
							btnDescr: 'Dashboard',
							redirectUrl: current_lang + ROUTER_PATH.dashboard,
						});
					},
				});
			},
		);
	}, []);

	const handleProductsToCart = (item: IProductsToCartItem): void => {
		setProductsToCart(prevState => {
			const newState = { ...prevState };

			newState[item.sku] = item;

			return newState;
		});
	};

	const handleCategoryChange = (uid: string): void => {
		if (uid) {
			const currentCategories = searchParams.get(FILTER_NAMES.category);

			if (currentCategories?.includes(uid)) {
				setCategoryFilter(state => {
					const chosenValue = state.chosenValue.filter(
						cat => cat.uid !== uid,
					);

					if (!chosenValue.length) {
						searchParams.delete(FILTER_NAMES.category);
					} else {
						const changedCats =
							currentCategories
								?.split(',')
								?.filter(str => str !== uid)
								.join(',') || '';

						searchParams.set(FILTER_NAMES.category, changedCats);
					}

					return {
						...state,
						chosenValue,
					};
				});
			} else {
				const newCategory = categoryFilter.filterValues.find(
					cat => cat.uid === uid,
				);

				newCategory &&
					setCategoryFilter(state => ({
						...state,
						chosenValue: [...state.chosenValue, newCategory],
					}));

				searchParams.set(
					FILTER_NAMES.category,
					currentCategories ? currentCategories + ',' + uid : uid,
				);
			}

			setSearchParams(searchParams);
		}
	};

	const handleApplicationChange = (item: TFilterValue): void => {
		const currentApplications = searchParams.get(FILTER_NAMES.application);

		if (currentApplications?.includes(item.value)) {
			setApplicationFilter(state => {
				const newChosenValue = state.chosenValue.filter(
					chv => chv.value !== item.value,
				);

				if (!newChosenValue.length) {
					searchParams.delete(FILTER_NAMES.application);
				} else {
					searchParams.set(
						FILTER_NAMES.application,
						currentApplications
							?.split(',')
							?.filter(str => str !== item.value)
							.join(',') || '',
					);
				}

				return {
					...state,
					chosenValue: newChosenValue,
				};
			});
		} else {
			searchParams.set(
				FILTER_NAMES.application,
				currentApplications
					? currentApplications + ',' + item.value
					: item.value,
			);

			setApplicationFilter(state => ({
				...state,
				chosenValue: [...state.chosenValue, item],
			}));
		}

		setSearchParams(searchParams);
	};

	const handleVersionChange = (item: TFilterValue): void => {
		const currentVersions = searchParams.get(FILTER_NAMES.version);

		if (currentVersions?.includes(item.value)) {
			searchParams.set(
				FILTER_NAMES.version,
				searchParams
					.get(FILTER_NAMES.version)
					?.split(',')
					?.filter(str => str !== item.value)
					.join(',') || '',
			);

			setVersionFilter(state => {
				const newChosenValue = state.chosenValue.filter(
					chv => chv.value !== item.value,
				);

				if (!newChosenValue.length) {
					searchParams.delete(FILTER_NAMES.version);
				} else {
					searchParams.set(
						FILTER_NAMES.version,
						currentVersions
							?.split(',')
							?.filter(str => str !== item.value)
							.join(',') || '',
					);
				}

				return {
					...state,
					chosenValue: newChosenValue,
				};
			});
		} else {
			searchParams.set(
				FILTER_NAMES.version,
				currentVersions
					? currentVersions + ',' + item.value
					: item.value,
			);

			setVersionFilter(state => ({
				...state,
				chosenValue: [...state.chosenValue, item],
			}));
		}

		setSearchParams(searchParams);
	};

	const handleCart = (): void => {
		const productsToCartArray = Object.values(productsToCart);

		cartMutations
			.updateCart({
				cart: cartQuery.data,
				items: productsToCartArray,
			})
			.then(() => {
				navigate(current_lang + ROUTER_PATH.overview + '?type=full');
			})
			.catch((errors: IDongleProductError[]) => {
				setProductsToCart(prevState => {
					const newState = { ...prevState };

					errors.forEach(item => {
						newState[item.sku].dongleErrors = item.errors;
					});

					return newState;
				});

				scrollToFirstInvalidControl('.invalid-input');
			});
	};

	const categsFilters = categoryFilter.chosenValue.length
		? categoryFilter.chosenValue
		: categoryFilter.filterValues;

	const appsFilters = applicationFilter.chosenValue.length
		? applicationFilter.chosenValue
		: applicationFilter.filterValues;

	const versnFilter = versionFilter.chosenValue.length
		? versionFilter.chosenValue
		: versionFilter.filterValues;

	const filteredProductsByName = useMemo(
		() =>
			searchbarValue
				? productList.filter(
						product =>
							product.name
								.toLowerCase()
								.includes(searchbarValue.toLowerCase()) ||
							product.sku
								.toLowerCase()
								.includes(searchbarValue.toLowerCase()),
					)
				: productList,
		[productList, searchbarValue],
	);

	const filteredByApplication = useMemo(
		() =>
			(pr: IProduct): boolean =>
				appsFilters.map(app => app.value).includes(pr.application),
		[applicationFilter.chosenValue],
	);

	const filteredByCategory = useMemo(
		() =>
			(pr: IProduct, category?: ICategory): boolean => {
				if (category)
					return !!pr.categories.find(
						fnd => fnd.uid === category.uid,
					);

				// for list view:
				if (categoryFilter.chosenValue.length)
					return !!pr.categories.find(fnd =>
						categoryFilter.chosenValue
							.map(categ => categ.uid)
							.includes(fnd.uid),
					);

				return true;
			},
		[categoryFilter.chosenValue],
	);

	const filteredByVersion = useMemo(
		() =>
			(pr: IProduct): boolean =>
				versnFilter.map(ver => ver.value).includes(pr.version),
		[versionFilter.chosenValue],
	);

	// for regular view
	const getSortedProductsByCategories = (): {
		category: ICategory;
		products: IProduct[];
	}[] =>
		categsFilters
			.sort((a, b) => a.position - b.position)
			.map(category => {
				const products = filteredProductsByName
					.filter(
						product =>
							filteredByApplication(product) &&
							filteredByCategory(product, category) &&
							filteredByVersion(product),
					)
					.sort(
						(a, b) =>
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							a.position_in_categories.find(
								fnd => fnd.category_uid === category.uid,
							)!.position -
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							b.position_in_categories.find(
								fnd => fnd.category_uid === category.uid,
							)!.position,
					);

				return {
					category,
					products,
				};
			});

	// for list view mode
	const getProductsForListView = (): IProduct[] =>
		filteredProductsByName.filter(
			product =>
				filteredByApplication(product) &&
				filteredByCategory(product) &&
				filteredByVersion(product),
		);

	const isChecked = (filterName: FILTER_NAMES, value: string): boolean =>
		!!searchParams.get(filterName)?.includes(value) || false;

	const hasCommitment = (sku: string): boolean | undefined =>
		commitments.data?.getCommitmentsList.some(
			item => item.products[0].product_sku === sku,
		);

	const noProductsFoundMessage = (
		<ContainerView>
			<MVTHeadline2 style={{ textAlign: 'center' }}>
				{t('product_detail_cant_find_products_by_your_search_typo')}
			</MVTHeadline2>
		</ContainerView>
	);

	if (
		isLoading ||
		categoriesQuery.loading ||
		filtersQuery.loading ||
		cartQuery.loading ||
		commitments.loading ||
		cartMutations.loading
	)
		return <Spinner />;

	return (
		<div style={{ flex: 1 }}>
			<ContainerView className="products">
				<ProductListPageFiltersContainerView className="products__filters-container">
					<ProductListPageFiltersGroupView className="products__filters">
						<Dropdown
							className="products__filters-dropdown"
							key={FILTER_NAMES.application}
							selected={
								searchParams.get(FILTER_NAMES.application) || ''
							}
							description={
								t(
									'products_page_filters_application_filter_typo',
								) ?? 'Produkte'
							}
							isMultiple
						>
							{applicationFilter.filterValues.map(item => (
								<Switch
									key={item.value}
									checked={isChecked(
										FILTER_NAMES.application,
										item.value,
									)}
									onChange={() =>
										handleApplicationChange(item)
									}
									style={switchStyles}
								>
									{item.label}
								</Switch>
							))}
						</Dropdown>

						<Dropdown
							className="products__filters-dropdown"
							key={FILTER_NAMES.category}
							selected={
								searchParams.get(FILTER_NAMES.category) || ''
							}
							description={t(
								'products_page_filters_category_filter_typo',
							)}
							isMultiple
						>
							{categoryFilter.filterValues.map(
								(item: ICategory) => (
									<Switch
										key={item.uid}
										checked={isChecked(
											FILTER_NAMES.category,
											item.uid,
										)}
										onChange={() =>
											handleCategoryChange(item.uid)
										}
										style={switchStyles}
									>
										{item.name}
									</Switch>
								),
							)}
						</Dropdown>

						<Dropdown
							className="products__filters-dropdown"
							key={FILTER_NAMES.version}
							selected={
								searchParams.get(FILTER_NAMES.version) || ''
							}
							description={t(
								'products_page_filters_version_filter_typo',
							)}
							isMultiple
						>
							{versionFilter.filterValues.map(item => (
								<Switch
									key={item.value}
									checked={isChecked(
										FILTER_NAMES.version,
										item.value,
									)}
									onChange={() => handleVersionChange(item)}
									style={switchStyles}
								>
									{item.label}
								</Switch>
							))}
						</Dropdown>

						<Searchbar
							className="products__searchbar"
							label={t('search_typo')}
							labelStyle={{
								fontFamily: '',
							}}
							value={searchbarValue}
							onChange={val => setSearchbarValue(val)}
						/>
					</ProductListPageFiltersGroupView>
				</ProductListPageFiltersContainerView>

				{appSettings?.grid_view_switcher_enabled && (
					<ProductListPageSwitchListViewGroupView>
						<TileViewIcon
							fill={
								!isListView
									? COLORS.primaryColor
									: COLORS.BG_Tooltip_GrayColor
							}
							onClick={() => setIsListView(false)}
						/>
						<ListViewIcon
							fill={
								isListView
									? COLORS.primaryColor
									: COLORS.BG_Tooltip_GrayColor
							}
							onClick={() => setIsListView(true)}
						/>
					</ProductListPageSwitchListViewGroupView>
				)}
			</ContainerView>

			<ProductListPageCategoryContainerView>
				{!isListView &&
					(getSortedProductsByCategories().every(
						item => !item.products.length,
					)
						? noProductsFoundMessage
						: getSortedProductsByCategories().map(
								({ category, products }) => {
									if (products.length)
										return (
											<div
												key={category.uid}
												className="products__category-group"
											>
												<ContainerView>
													<ProductListPageCategoryNameView>
														{category.name}
													</ProductListPageCategoryNameView>

													<ProductListPageProductTileGroupView>
														{products.map(
															product => (
																<ProductTile
																	key={
																		product.sku
																	}
																	item={
																		product
																	}
																	onClick={() =>
																		navigate(
																			product.sku,
																		)
																	}
																	hasCommitment={hasCommitment(
																		product.sku,
																	)}
																/>
															),
														)}
													</ProductListPageProductTileGroupView>
												</ContainerView>
											</div>
										);
								},
							))}

				{isListView &&
					(!getProductsForListView().length ? (
						noProductsFoundMessage
					) : (
						<ContainerView
							style={{
								width: '100vw',
								overflowX: 'auto',
							}}
						>
							<ProductListPageListGroupTableView>
								<thead>
									<tr>
										<td>
											<TypoHeadline4>
												PRODUKT-ID
											</TypoHeadline4>
										</td>
										<td>
											<TypoHeadline4>
												PRODUKTNAME
											</TypoHeadline4>
										</td>
										<td>
											<TypoHeadline4>
												{t('quantity_typo')}
											</TypoHeadline4>
										</td>
										<td>
											<TypoHeadline4>
												DongleNUMMER/MAC Adresse
											</TypoHeadline4>
										</td>
									</tr>
								</thead>

								<tbody>
									{getProductsForListView().map(product => (
										<ProductListItem
											key={product.sku}
											product={product}
											onProductClick={() =>
												navigate(product.sku)
											}
											item={productsToCart[product.sku]}
											handleItem={handleProductsToCart}
											hasCommitment={hasCommitment(
												product.sku,
											)}
										/>
									))}
								</tbody>
							</ProductListPageListGroupTableView>
						</ContainerView>
					))}

				{isListView && (
					<ContainerView
						style={{
							display: 'grid',
							justifyItems: 'start',
							gap: 20,
						}}
					>
						<div className="price-notification">
							{t('product_detail_price_notification_typo')}
						</div>
						<Button
							primary
							disabled={!Object.keys(productsToCart).length}
							onClick={handleCart}
						>
							{t('product_detail_in_shopping_cart_typo')}
						</Button>
					</ContainerView>
				)}
			</ProductListPageCategoryContainerView>

			<Modal
				show={!!modalMessages.length}
				onClose={() => setModalMessages([])}
			>
				<div style={{ padding: 20 }}>
					<div
						style={{
							margin: '0 auto',
							width: 'fit-content',
							marginBottom: 25,
						}}
					>
						<WarningIcon
							fill={COLORS.secondaryColor}
							width="100px"
							height="100px"
						/>
					</div>
					<ul style={{ paddingLeft: 25, paddingBottom: 0 }}>
						{modalMessages.map((mm, i) => (
							<li key={i} style={{ marginBottom: 15 }}>
								<TypoHeadline2>{mm}</TypoHeadline2>
							</li>
						))}
					</ul>
				</div>
			</Modal>
		</div>
	);
};
