import { FC, MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { IProduct } from '../../../../models/IProduct';
import { ROUTER_PATH } from '../../../../router/routes';
import { COLORS } from '../../../../components/styleguide/colors';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Buttons/Buttons';

interface IProductWrapperViewProps {
	hasCommitment?: boolean;
}

const ProductWrapperView = styled.a<IProductWrapperViewProps>`
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
	/* overflow: hidden; */
	position: relative;
	background-color: ${COLORS.BG_GrayColor};
	color: ${COLORS.primaryColor};
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	text-decoration: none;
	cursor: pointer;

	.product__button {
		margin: 0 auto;
		margin-top: 20px;
		/* position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 15px; */
		transition: 0.4s ease-in-out;
		opacity: 0;
		text-transform: none;
	}

	&:hover {
		.product__button {
			opacity: 1;
		}

		/* img {
			height: 70%;
		} */
	}

	&::after {
		display: ${({ hasCommitment }) => (hasCommitment ? 'block' : 'none')};
		content: '';
		width: 25px;
		height: 25px;
		background-color: ${COLORS.secondaryColor};
		border-radius: 100%;
		border: 3px solid ${COLORS.white};
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(35%, -35%);
	}
`;
interface IProductMouseOverViewProps {
	hover?: boolean;
}

const ProductMouseOverView = styled.a<IProductMouseOverViewProps>`
	display: ${({ hover }) => (hover ? 'block' : 'none')};
	position: absolute;
	width: fit-content;
	block-size: fit-content;
	min-width: 200px;
	left: 100%;
	top: -20%;
	border: 1px solid;
	border-color: ActiveBorder;
	background: white;
	padding: 20px;
	font-size: 12px;
	z-index: 10;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

const ProductNameView = styled.div`
	margin-bottom: 15px;
	width: 75%;
	min-height: 40px;
	font-weight: 700;
	font-size: 17px;
	line-height: 20px;
`;

const ProductImageWrapperView = styled.div`
	height: 155px;
	margin-bottom: 5px;

	& > img {
		transition: 0.3s ease-in-out;
		display: block;
		margin: 0 auto;
		max-width: 100%;
		height: 100%;
	}
`;

interface IProductTileProps {
	item: IProduct;
	onClick: () => void;
	hasCommitment?: boolean;
}

export const ProductTile: FC<IProductTileProps> = ({
	item,
	onClick,
	hasCommitment,
}) => {
	const { i18n, t } = useTranslation();

	const [hover, setHover] = useState(false);

	const current_lang = i18n.language;

	const handleClickProduct = (e: MouseEvent<HTMLAnchorElement>): void => {
		e.preventDefault();

		onClick();
	};

	return (
		<ProductWrapperView
			className="product"
			href={current_lang + ROUTER_PATH.products + '/' + item.sku}
			onClick={handleClickProduct}
			hasCommitment={hasCommitment}
		>
			<ProductNameView className="product__name">
				{item.name}
			</ProductNameView>

			<div
				onMouseOver={() => setHover(true)}
				onMouseOut={() => setHover(false)}
			>
				{item.product_tech_description &&
					item.product_tech_description.length > 0 && (
						<ProductMouseOverView
							className="product__mouseover"
							hover={hover}
							dangerouslySetInnerHTML={{
								__html: item.product_tech_description,
							}}
						></ProductMouseOverView>
					)}
				<ProductImageWrapperView className="product__image">
					<img src={item.image.url} alt={item.image.label} />
				</ProductImageWrapperView>

				<Button primary className="product__button">
					{t('product_details_button')}
				</Button>
			</div>
		</ProductWrapperView>
	);
};
