import { useContext, useState } from 'react';
import {
	useMutation,
	FetchResult,
	MutationFunctionOptions,
} from '@apollo/client';
import { MESSAGE_TYPE } from '../pages/MessagePage/MessagePage';
import {
	ADD_PRODUCTS_TO_CART_MUTATION,
	IAddProductToCartMutationResponse,
	ICartMutationResponse,
	IUpdateItemFromCartMutationResponse,
	UPDATE_CART_ITEM_MUTATION,
} from '../graphql/mutations/cart';
import {
	GET_CUSTOMER_CART,
	IGetCustomerCartQueryResponse,
} from '../graphql/queries/cart';
import { ICartItemPayload, ICartUpdateItemPayload } from '../models/ICart';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';
import { LANG } from '../models/ILang';
import { useCartId } from './useCartId';
import { useCheckSession } from './useCheckSession';
import { validateDongles } from '../utils/dongles';
import { PRODUCT_TYPES } from '../models/IProduct';
import { IProductsToCartItem } from '../pages/ProductListPage/ProductListPage';
import { IDongleProductError } from '../models/IDongle';

interface IAddProductsToCartProps
	extends Omit<
		MutationFunctionOptions<IAddProductToCartMutationResponse>,
		'variables'
	> {
	variables: {
		cartId: string | undefined;
		items: ICartItemPayload[];
	};
}

interface IUpdateProductToCart
	extends Omit<
		MutationFunctionOptions<IUpdateItemFromCartMutationResponse>,
		'variables'
	> {
	variables: {
		cartId: string | undefined;
		items: ICartUpdateItemPayload[];
	};
}

interface IUpdateCartProps {
	cart: IGetCustomerCartQueryResponse | undefined;
	items: IProductsToCartItem[];
}

type TUseCartMutationsReturnValue = {
	loading: boolean;
	addProductsToCart: (
		props: IAddProductsToCartProps,
	) => Promise<FetchResult<IAddProductToCartMutationResponse>>;
	updateProductsToCart: (
		props: IUpdateProductToCart,
	) => Promise<FetchResult<IUpdateItemFromCartMutationResponse>>;
	updateCart: (
		props: IUpdateCartProps,
	) => Promise<ICartMutationResponse | undefined>;
};

export const useCartMutations = (): TUseCartMutationsReturnValue => {
	const { navigateToMessagePage, setModal } = useContext(AppContext);
	const { t, i18n } = useTranslation();
	const current_lang = i18n.language as LANG;
	const cartIdNumber = useCartId();
	const checkSession = useCheckSession();
	const [loading, setLoading] = useState<boolean>(false);

	// CART PROCESSES:
	const [addProductToCartMutation] =
		useMutation<IAddProductToCartMutationResponse>(
			ADD_PRODUCTS_TO_CART_MUTATION,
			{
				onError(error) {
					console.error('addProductToCartMutation error:>>', error);

					setLoading(false);

					navigateToMessagePage(current_lang, {
						type: MESSAGE_TYPE.error,
						title: error.message,
						btnDescr: 'Product',
						redirectUrl: location.pathname,
					});
				},
				refetchQueries: [{ query: GET_CUSTOMER_CART }],
			},
		);

	const [updateCartItemMutation] =
		useMutation<IUpdateItemFromCartMutationResponse>(
			UPDATE_CART_ITEM_MUTATION,
			{
				onError(error) {
					console.error('updateCartItemMutation error:>>', error);

					setLoading(false);

					navigateToMessagePage(current_lang, {
						type: MESSAGE_TYPE.error,
						title: error.message,
						btnDescr: 'Products',
						redirectUrl: location.pathname,
					});
				},
				refetchQueries: [{ query: GET_CUSTOMER_CART }],
			},
		);

	const addProductsToCart = async (
		props: IAddProductsToCartProps,
	): Promise<FetchResult<IAddProductToCartMutationResponse>> => {
		const { cartId, items } = props.variables;

		return await cartIdNumber.getCartId(cartId).then(id => {
			return addProductToCartMutation({
				...props,
				variables: {
					cartId: id,
					items,
				},
			});
		});
	};

	const updateProductsToCart = async (
		props: IUpdateProductToCart,
	): Promise<FetchResult<IUpdateItemFromCartMutationResponse>> => {
		const { variables } = props;
		const { cartId, items } = variables;
		const updatedItems = items.map(item => ({
			cart_item_uid: item.cartItemUid,
			quantity: item.quantity,
			additional: {
				dongle_sku: item.additional.dongle_sku,
				host_id: item.additional.host_id,
			},
		}));

		return await cartIdNumber.getCartId(cartId).then(id => {
			return updateCartItemMutation({
				...props,
				variables: {
					cartId: id,
					items: {
						...updatedItems,
					},
				},
			});
		});
	};

	// updating items and adding new items
	const updateCart = (
		props: IUpdateCartProps,
	): Promise<ICartMutationResponse | undefined> => {
		return new Promise(
			(resolve, reject: (value: IDongleProductError[]) => void) => {
				const { cart, items } = props;

				setLoading(true);

				let donglesQuantityError = false;
				const dongleErrors: IDongleProductError[] = [];
				const cartItemsExist: string[] = [];
				const checkedItems: ICartItemPayload[] = items.map(item => {
					let dongles: string[] = [];

					cart &&
						cart.customerCart.items.length >= items.length &&
						cart.customerCart.items.forEach(cartItem => {
							if (
								cartItem.product.sku === item.sku &&
								cartItem.quantity === item.quantity &&
								cartItem.additional.dongle_sku ===
									item.additional.dongle_sku &&
								cartItem.additional.host_id.toString() ===
									item.additional.host_id.toString()
							) {
								cartItemsExist.push(item.sku);
							}
						});

					if (cartItemsExist.length === items.length) {
						setModal(() => ({
							type: 'warning',
							messages: [
								t(
									'modal_messages_configuration_already_in_cart_warning',
								),
							],
						}));

						return item;
					}

					if (
						item.product_type !== PRODUCT_TYPES.material &&
						cartItemsExist.length !== items.length &&
						item.quantity
					) {
						// if dongle_sku is empty string -> validate dongles
						if (!item.additional.dongle_sku) {
							dongles = item.additional.host_id.length
								? item.additional.host_id
										// slice in case if customer entered dongles and reduced quantity later
										// (we save all entered dongles to return them
										// into inputs if customer want to increase quantity again)
										.slice(0, item.quantity)
										.reduce<string[]>((acc, dongle) => {
											// filter out empty strings and trim
											item && acc.push(dongle.trim());

											return acc;
										}, [])
								: Array.from(Array(item.quantity));

							const errors = validateDongles(dongles, {
								excludeMacAddressInput:
									!!item.exclude_mac_address,
							});

							errors.length &&
								dongleErrors.push({
									sku: item.sku,
									errors,
								});

							// need to set only one time so used flag donglesQuantityError
							if (
								(dongles.some(d => !d) &&
									!donglesQuantityError) ||
								(dongles.length < item.quantity &&
									!donglesQuantityError)
							) {
								donglesQuantityError = true;

								setModal(m => ({
									type: 'warning',
									messages: [
										...m.messages,
										t(
											'modal_messages_select_dongle_mac_warning',
										),
									],
								}));
							}
						} else {
							dongleErrors.push({
								sku: item.sku,
								errors: [],
							});
						}
					}

					// if customer entered dongles earlier,
					// but choose to include dongle (entered dongles are not deleted)
					// -> clean host_id array
					const hostId = !item.additional.dongle_sku ? dongles : [];

					return {
						sku: item.sku,
						quantity: item.quantity,
						additional: {
							dongle_sku: item.additional.dongle_sku ?? '',
							host_id: hostId,
						},
					};
				});

				if (
					cartItemsExist.length !== items.length &&
					!donglesQuantityError
				) {
					const addedItems: ICartItemPayload[] = [];
					const updatedItems: ICartUpdateItemPayload[] = [];
					if (cart?.customerCart.items.length) {
						checkedItems.forEach(item => {
							const cartItem = cart?.customerCart.items.find(
								fnd => fnd.product.sku === item.sku,
							);

							if (cartItem) {
								updatedItems.push({
									...item,
									cartItemUid: cartItem.uid,
								});

								return;
							} else {
								addedItems.push(item);
							}
						});
					} else {
						addedItems.push(...checkedItems);
					}

					checkSession(() => {
						if (!updatedItems.length) {
							resolve(
								addProductsToCart({
									variables: {
										cartId: cart?.customerCart.id,
										items: addedItems,
									},
								}).then(res => {
									setLoading(false);

									return res.data?.addProductsToCart;
								}),
							);
						} else {
							resolve(
								updateProductsToCart({
									variables: {
										cartId: cart?.customerCart.id,
										items: updatedItems,
									},
								}).then(updateData => {
									if (addedItems.length) {
										return addProductsToCart({
											variables: {
												cartId: cart?.customerCart.id,
												items: addedItems,
											},
										}).then(addData => {
											setLoading(false);

											return addData.data
												?.addProductsToCart;
										});
									} else {
										setLoading(false);

										return updateData.data?.updateCartItems;
									}
								}),
							);
						}
					});
				} else {
					setLoading(false);
					reject(dongleErrors);
				}
			},
		);
	};

	return {
		loading: loading || cartIdNumber.loading,
		addProductsToCart,
		updateProductsToCart,
		updateCart,
	};
};
