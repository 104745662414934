import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App, { LOCAL_STORAGE_ENTRIES } from './App';
import reportWebVitals from './reportWebVitals';
import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	concat,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { getLangFromPath } from './utils/helpers';
import './i18n';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

const httpLink = new HttpLink({
	uri:
		process.env.REACT_APP_GQL_URL ||
		`${window.location.protocol}//${window.location.host}/graphql`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
	const token = localStorage.getItem(LOCAL_STORAGE_ENTRIES.mvt_auth_token);

	const m2StoreValue = getLangFromPath(location.pathname)
		.slice(1)
		.toUpperCase();

	operation.setContext(({ headers = {} }) => ({
		headers: {
			...headers,
			authorization: `Bearer ${token}`,
			store: m2StoreValue,
		},
	}));

	return forward(operation);
});

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				customer: {
					merge: true,
				},
			},
		},
	},
});

const client = new ApolloClient({
	cache: cache,
	link: concat(authMiddleware, httpLink),
});

// client.resetStore();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<CookiesProvider>
				<ApolloProvider client={client}>
					<I18nextProvider i18n={i18n}>
						<App />
					</I18nextProvider>
				</ApolloProvider>
			</CookiesProvider>
		</BrowserRouter>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
