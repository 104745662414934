import { gql } from '@apollo/client';
import { IPrice } from '../../models/ICart';
import { IGenerateCustomerTokenData } from '../mutations/customer';

export interface IAddressesQueryResponse {
	default_billing: boolean;
	default_shipping: boolean;
	firstname: string;
	lastname: string;
	company: string;
	street: string[];
	city: string;
	postcode: string;
	country_code: string;
	email?: string
}


export interface IGetCustomerQueryResponse {
	customer: {
			firstname: string;
			lastname: string;
			prefix: string;
			email: string;
			addresses: IAddressesQueryResponse[];
		};
}

export const GET_CUSTOMER_QUERY = gql`
	query GetCustomer {
		customer {
			firstname
			lastname
			prefix
			email
			addresses {
				default_billing
				default_shipping
				firstname
				lastname
				company
				street
				city
				region {
					region_code
					region
				}
				postcode
				country_code
				telephone
			}
		}
	}
`;

export interface IGetDetailsOfOrderQueryResponse {
	customer: {
		orders: {
			items: {
					sap_order_id: string;
					id: string;
					number: string;
					order_date: string;
					status: string;
					items: {
						product_name: string;
						product_sku: string;
						product_url_key: string;
						product_sale_price: IPrice;
						quantity_ordered: number;
						quantity_invoiced: number;
						quantity_shipped: number;
						product_image: string;
					}[];
					carrier: string;
					shipments: {
						id: string;
						number: string;
						items: {
							product_name: string;
							quantity_shipped: number;
						};
					}[];
					total: {
						grand_total: IPrice;
					};
				}[];
			total_count: number;
		};
	};
}

export const GET_DETAILS_OF_ORDER_QUERY = gql`
	query {
		customer {
			orders(
				pageSize: 100
			) {
				total_count
				items {
					sap_order_id
					id
					number
					order_date
					status
					items {
						product_name
						product_sku
						product_url_key
						product_sale_price {
							value
							currency
						}
						quantity_ordered
						quantity_invoiced
						quantity_shipped
						product_image
					}
					carrier
					shipments {
						id
						number
						items {
							product_name
							quantity_shipped
						}
					}
					total {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}
	}
`;

export interface IGetSapOrderHistoryQueryResponse {
	getDonglesList: {
		dongle_id: string;
		sell_date: string;
		sales_order_id: string;
		sales_order_name: string;
		licence_customer: string;
		licence_customer_id: string;
	}[];
}

export const GET_SAP_ORDER_HISTORY_QUERY = gql`
	query {
		getDonglesList {
			dongle_id
			sell_date
			sales_order_id
			sales_order_name
			licence_customer
			licence_customer_id
		}
	}
`;

export interface IInitCustomerQueryResponse {
	initCustomer: IGenerateCustomerTokenData;
}

export const INIT_CUSTOMER_QUERY = gql`
	query {
		initCustomer {
			session_lifetime
			country_code
			has_catalogs
			is_sap_customer
			has_internal_distributors
			token
		}
	}
`;

export interface OpenPosition {
	dueDate: string;
	amount: IPrice;
	invoiceNumber: string;
}

export interface OpenPositionResponse {
	open_positions: OpenPosition[];
}

export const GET_OPEN_POSITIONS = gql`
	query {
		open_positions {
        	dueDate
        	amount{
				value
				currency
			}
        	invoiceNumber
		}
	}
`;
