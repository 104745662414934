import {
	ChangeEvent,
	CSSProperties,
	FocusEvent,
	ForwardedRef,
	forwardRef,
	HTMLInputTypeAttribute,
	KeyboardEvent,
	MouseEvent,
	ReactElement,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { Tooltip } from '../Tooltip/Tooltip';

interface IInputContainerViewProps {
	label?: string | null;
}

const InputContainerView = styled.div<IInputContainerViewProps>`
	position: relative;
	margin-top: ${({ label }) => (label ? '10px' : '')};
`;

interface InputViewProps {
	fullBorder?: boolean | undefined;
}

const InputView = styled.input<InputViewProps>`
	width: 100%;
	border: none;
	border-radius: 0;
	border-bottom-width: 1px;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	border-width: ${({ fullBorder }) => (fullBorder ? '1px' : '')};
	border-style: solid;
	border-color: ${COLORS.primaryColor};
	outline: none;
	font-family: 'Futura';
	color: ${COLORS.primaryColor};
	font-size: 18px;
	font-weight: 400;
	padding: 3px;

	&:focus ~ .floating-label {
		top: -5px;
		left: 3px;
		font-family: 'Arial';
		font-size: 10px;
		font-weight: 700;
	}

	&:is(.invalid-input) {
		border-color: ${COLORS.errorColor};
	}
`;

interface InputLabelViewProps {
	dirty: boolean;
}

const InputLabelView = styled.span<InputLabelViewProps>`
	width: 100%;
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 1;
	pointer-events: none;
	top: ${({ dirty }) => (dirty ? '-5px' : '50%')};
	left: 3px;
	transform: translateY(-50%);
	transition: 0.2s ease all;

	font-family: ${({ dirty }) => dirty && 'Arial'};
	color: ${COLORS.textGray};
	font-size: ${({ dirty }) => (dirty ? 10 : 18)}px;
	font-weight: ${({ dirty }) => (dirty ? 700 : 400)};

	.tooltip {
		pointer-events: all;
		margin-left: ${({ dirty }) => (dirty ? 'auto' : '5px')};
	}
`;

const InputErrorMessageView = styled.div`
	padding-left: 3px;
	padding-top: 2px;
	color: ${COLORS.errorColor};
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;

	a {
		color: ${COLORS.errorColor};
		font-weight: 600;
		text-decoration: none;
		cursor: pointer;
	}
`;

interface IInputProps {
	className?: string;
	id?: string;
	type?: HTMLInputTypeAttribute;
	placeholder?: string;
	label?: string | null;
	labelStyle?: CSSProperties;
	style?: CSSProperties;
	value?: string | number;
	required?: boolean;
	onChange?: (value: string) => void;
	onUpload?: (value: File) => void;
	onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	isInvalid?: boolean;
	tooltip?: string;
	autoFocus?: boolean;
	fullBorder?: boolean;
	errorMessages?: string[];
	maxLength?: number;
}

// eslint-disable-next-line react/display-name
export const Input = forwardRef(
	(
		{
			className,
			id,
			type = 'text',
			placeholder,
			label,
			labelStyle,
			style,
			value,
			required,
			onChange,
			onUpload,
			onKeyDown,
			onBlur,
			onFocus,
			onClick,
			isInvalid,
			tooltip,
			autoFocus,
			fullBorder,
			errorMessages,
			maxLength,
		}: IInputProps,
		ref: ForwardedRef<HTMLInputElement>,
	): ReactElement => {
		const [inputValue, setInputValue] = useState<string | number>('');

		const [dirty, setDirty] = useState<boolean>(!!value);

		const [error, setError] = useState<boolean>(false);

		useEffect(() => {
			(!!value || value === '') && setInputValue(value ?? '');

			if (value) {
				setDirty(true);

				!required && setError(false);
			}
		}, [value]);

		const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
			if (type === 'file') {
				const file = e.target.files;

				onUpload && !!file && onUpload(file[0]);

				return;
			}

			const targetValue = e.target.value;

			setInputValue(targetValue);

			if (required && !targetValue) {
				setError(true);
			} else if (!isInvalid && targetValue) {
				setError(false);

				placeholder && setDirty(true);
			} else {
				placeholder && !value && setDirty(false);
			}

			onChange && onChange(targetValue);
		};

		const handleInputBlur = (e: FocusEvent<HTMLInputElement>): void => {
			if (required && !e.target.value) {
				setError(true);

				placeholder && !value && setDirty(false);
			} else if (!e.target.value && !value) {
				setDirty(false);
			}

			onBlur && onBlur(e);
		};

		const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
			onKeyDown && onKeyDown(e);
		};

		const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
			setDirty(true);

			onFocus && onFocus(e);
		};

		const handleClick = (e: MouseEvent<HTMLInputElement>): void => {
			if (type === 'date') e.currentTarget?.showPicker();

			onClick && onClick();
		};

		return (
			<div className="input">
				<InputContainerView className={className} label={label}>
					<InputView
						ref={ref}
						id={id}
						className={
							error || isInvalid || errorMessages?.length
								? 'invalid-input'
								: undefined
						}
						type={type}
						value={onUpload ? undefined : inputValue}
						placeholder={placeholder}
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						onBlur={handleInputBlur}
						onFocus={handleFocus}
						onClick={handleClick}
						required={required}
						autoFocus={autoFocus}
						fullBorder={fullBorder}
						style={style}
						accept={type === 'file' ? '.csv' : ''}
						maxLength={maxLength}
					/>

					{!placeholder && label && (
						<InputLabelView
							className="floating-label"
							dirty={dirty}
							style={labelStyle}
						>
							{label}
							{required && (
								<div style={{ color: COLORS.errorColor }}>
									*
								</div>
							)}
							{tooltip && <Tooltip>{tooltip}</Tooltip>}
						</InputLabelView>
					)}
				</InputContainerView>

				{!!errorMessages?.length &&
					errorMessages.map((msg, i) => (
						<InputErrorMessageView
							key={i}
							dangerouslySetInnerHTML={{ __html: msg }}
						/>
					))}
			</div>
		);
	},
);
