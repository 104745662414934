import { FC } from 'react';
import { OverviewTotalPaymentContainerView } from '../../pages/OverviewPage/components/OverviewTotalPayment/OverviewTotalPayment';
import { ICustomerCartItemQueryResponse } from '../../models/ICart';
import { formatNumber2Price } from '../../utils/price';
import {
	TypoHeadline3,
	TypoHeadline4,
	PriceView,
} from '../styleguide/typography';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import styled from 'styled-components';
import { COLORS } from '../styleguide/colors';
import { GRID_BREAKPOINTS } from '../styleguide/variables';
import { useTranslation } from 'react-i18next';
import { isDongleProduct } from '../../utils/helpers';
import { IOfferQuoteItemMutationResponse } from '../../graphql/mutations/offer';

const OverviewCartItemContainer = styled.div`
	flex: 1;
	border-bottom: 1.5px solid ${COLORS.disabledColor};

	.overview__product {
		&-container {
			display: grid;
			grid-template-columns: 1fr auto auto;
			grid-gap: 10px;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				grid-gap: 15px;
			}

			& > svg path {
				fill: ${COLORS.primaryColor};
			}
		}

		&-image {
			max-width: 100px;
			max-height: 140px;
			display: flex;
			align-items: center;

			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				max-width: 180px;
				max-height: 180px;
			}

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		&-info {
			@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
				margin-right: 25px;
			}
			@media (min-width: ${GRID_BREAKPOINTS.$lg}px) {
				margin-right: 50px;
			}

			h3 {
				margin-bottom: 12px;
			}

			ul {
				margin-bottom: 20px;
				padding-left: 20px;
				font-family: Arial, Helvetica, sans-serif;
				font-size: 16px;

				@media (min-width: ${GRID_BREAKPOINTS.$sm}px) {
					font-family: Futura;
				}
			}

			h4 {
				text-transform: uppercase;
			}
		}

		&-qty {
			display: flex;
			gap: 5px;

			span {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 16px;
				font-weight: 400;
			}
		}
		&-price {
			text-align: end;
		}
	}
`;

interface IOverviewCartItemProps {
	item: ICustomerCartItemQueryResponse | IOfferQuoteItemMutationResponse;
	onEdit?: () => void;
}

export const OverviewCartItem: FC<IOverviewCartItemProps> = ({
	item,
	onEdit,
}) => {
	const { t } = useTranslation();

	return (
		<OverviewCartItemContainer className="overview__product-item">
			<div className="overview__product-container">
				<div className="overview__product-info">
					<TypoHeadline3>{item.product.name}</TypoHeadline3>
					<ul>
						<li>{t('shopping_cart_industrial_typo')}</li>

						{!isDongleProduct(item.product.name) ? (
							item.additional.host_id?.length ? (
								<li>
									{t('shopping_cart_without_dongle_typo')}
								</li>
							) : (
								<li>{t('shopping_cart_with_dongle_typo')}</li>
							)
						) : null}
					</ul>
					<div className="overview__product-qty">
						<TypoHeadline4>{t('quantity_typo')}</TypoHeadline4>
						<span>{item.quantity}</span>
					</div>
				</div>
				<div className="overview__product-image">
					<img
						src={item.product.image.url}
						alt={item.product.image.label}
					/>
				</div>
				{onEdit && (
					<EditIcon
						style={{ cursor: 'pointer', minWidth: 23 }}
						onClick={onEdit}
					/>
				)}
			</div>

			<OverviewTotalPaymentContainerView>
				<div></div>
				<PriceView className="overview__product-price">
					{formatNumber2Price(item.prices.price)}
				</PriceView>

				{!!item.prices.discount_amount && (
					<>
						<div className="overview__totalPayment-descr">
							{t('discount_typo')}
						</div>
						<PriceView className="overview__totalPayment-cost">
							-&nbsp;
							{formatNumber2Price({
								value: Math.abs(item.prices.discount_amount),
								currency:
									item.prices.row_total_including_tax
										.currency,
							})}
						</PriceView>
					</>
				)}
			</OverviewTotalPaymentContainerView>
		</OverviewCartItemContainer>
	);
};
