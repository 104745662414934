import { FC, useEffect, useState } from 'react';
import { IProduct, PRODUCT_TYPES } from '../../../../models/IProduct';
import styled from 'styled-components';
import { Counter } from '../../../../components/Counter/Counter';
import { TypoBody } from '../../../../components/styleguide/typography';
import { DonglesInputs } from '../../../../components/DonglesInputs/DonglesInputs';
import { IProductsToCartItem } from '../../ProductListPage';
import { COLORS } from '../../../../components/styleguide/colors';
import { GRID_BREAKPOINTS } from '../../../../components/styleguide/variables';
import { Switch } from '../../../../components/Switch/Switch';
import { useTranslation } from 'react-i18next';

const ProductListItemContainerView = styled.tr`
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: ${COLORS.BG_TileColor};

	&:is(.invalid-input, .error) {
		border-bottom-color: ${COLORS.errorColor};
	}
`;

const ProductListItemProductIdView = styled.td``;

const ProductListItemProductNameView = styled.td`
	display: flex;
	align-items: center;
	gap: 5px;

	& > div {
		width: 265px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		@media (min-width: ${GRID_BREAKPOINTS.$xs}px) {
			max-width: 700px;
			width: auto;
		}
	}

	& > span {
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background-color: ${COLORS.secondaryColor};
	}
`;

const ProductListItemCounterContainerView = styled.td``;

const ProductListItemDonglesContainerView = styled.td<{ disabled: boolean }>`
	opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

	.options {
		display: flex;
		gap: 10px;
	}
`;

const Text = styled(TypoBody)`
	font-weight: 400;
`;

interface IProductListItemProps {
	product: IProduct;
	onProductClick: () => void;
	item: IProductsToCartItem | undefined;
	handleItem: (payload: IProductsToCartItem) => void;
	hasCommitment?: boolean;
}

export const ProductListItem: FC<IProductListItemProps> = ({
	product,
	onProductClick,
	item,
	handleItem,
	hasCommitment,
}) => {
	const { t } = useTranslation();

	const [includeDongle, setIncludeDondle] = useState<boolean>(true);

	useEffect(() => {
		item?.additional.host_id.length && setIncludeDondle(false);
	}, []);

	const handleDongleSwitch = (bool: boolean): void => {
		setIncludeDondle(bool);

		item &&
			handleItem({
				...item,
				additional: {
					...item.additional,
					dongle_sku: bool ? product.dongle_sku : '',
				},
			});
	};

	const handleDonglesInput = (
		value: string | string[],
		index: number,
	): void => {
		if (item) {
			const newItem = { ...item };

			let updatedValues = [...newItem.additional.host_id];

			if (typeof value === 'string') {
				updatedValues[index] = value;
			} else {
				newItem.quantity = value.length;

				updatedValues = value;
			}

			newItem.additional.host_id = updatedValues;

			newItem.dongleErrors = newItem.dongleErrors?.filter(
				error => error.index !== index,
			);

			handleItem(newItem);
		}
	};

	const handleCounter = (quantity: number): void => {
		const payload: IProductsToCartItem = {
			quantity,
			sku: product.sku,
			additional: {
				dongle_sku:
					product.dongle_sku && !item?.additional.host_id.length
						? product.dongle_sku
						: '',
				host_id: item?.additional.host_id ?? [],
			},
			product_type: product.product_type,
			exclude_mac_address: item?.exclude_mac_address ?? false,
			dongleErrors: item?.dongleErrors ?? [],
		};

		handleItem(payload);
	};

	const dongles =
		product.product_type !== PRODUCT_TYPES.material ? (
			<>
				<div className="options">
					{product.dongle_sku && (
						<>
							<Switch
								type="radio"
								checked={includeDongle}
								tooltip={t(
									'product_detail_page_dongle_dongle_options_tooltip',
								)}
								onChange={() => handleDongleSwitch(true)}
							>
								{t(
									'product_detail_page_dongle_dongle_options_title_no',
								)}
							</Switch>
							<Switch
								type="radio"
								checked={!includeDongle}
								onChange={() => handleDongleSwitch(false)}
							>
								{t(
									'product_detail_page_dongle_dongle_options_title_yes',
								)}
							</Switch>
						</>
					)}
				</div>
				{(!includeDongle || !product.dongle_sku) && (
					<DonglesInputs
						quantity={item?.quantity ?? 0}
						dongles={item?.additional.host_id ?? []}
						handleDonglesInput={handleDonglesInput}
						csvLoadable
						errors={item?.dongleErrors ?? []}
						style={{
							gridTemplateAreas: `'dongle-switch mac-switch'
									'dongle-inputs dongle-inputs'
									'mac-inputs mac-inputs'`,
						}}
						listPage
						excludeMacAddressInput={!!product.exclude_mac_address}
					/>
				)}
			</>
		) : null;

	return (
		<>
			<ProductListItemContainerView
				className={
					item?.dongleErrors?.length ? 'invalid-input' : undefined
				}
			>
				<ProductListItemProductIdView>
					<Text>{product.sku}</Text>
				</ProductListItemProductIdView>
				<ProductListItemProductNameView onClick={onProductClick}>
					<Text>{product.name}</Text>
					{hasCommitment && <span />}
				</ProductListItemProductNameView>
				<ProductListItemCounterContainerView>
					<Counter
						onChange={n => handleCounter(n)}
						value={item?.quantity ?? 0}
						min={0}
					/>
				</ProductListItemCounterContainerView>
				<ProductListItemDonglesContainerView disabled={!item?.quantity}>
					{dongles}
				</ProductListItemDonglesContainerView>
			</ProductListItemContainerView>
			<ProductListItemContainerView
				className={item?.dongleErrors?.length ? 'error' : undefined}
			>
				<ProductListItemDonglesContainerView
					colSpan={3}
					disabled={!item?.quantity}
				>
					{dongles}
				</ProductListItemDonglesContainerView>
			</ProductListItemContainerView>
		</>
	);
};
