import { FC } from 'react';
import { ROUTER_PATH } from '../../router/routes';
import { formatNumber2Price } from '../../utils/price';
import { Button } from '../Buttons/Buttons';
import { PriceView } from '../styleguide/typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { INotification } from '../../models/INotification';

import styles from './Notifications.module.scss';

interface INotificationOfferItemProps {
	type: 'offer' | 'commitment';
	item: INotification;
}

export const NotificationItem: FC<INotificationOfferItemProps> = ({
	item,
	type,
}) => {
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const current_lang = i18n.language;

	const handleNotificationClick = (): void => {
		switch (type) {
			case 'offer':
				navigate(
					current_lang +
						ROUTER_PATH.overview +
						`?offer=${item.item_id}`,
				);
				break;
			case 'commitment':
				navigate(
					current_lang +
						ROUTER_PATH.products +
						'/' +
						item.products[0].product_sku,
				);
				break;
			default:
				break;
		}
	};

	return (
		<div
			className={
				item.is_valid == false
					? `${styles.notification} ${styles.notification__disabled}`
					: styles.notification
			}
			key={item.item_id}
			onClick={
				item.is_valid == false ? undefined : handleNotificationClick
			}
		>
			{item.products[0] && ( // TODO: why can this be null?
				<div className={styles.notification__thumbnail}>
					<img
						src={item.products[0].product_thumbnail}
						alt={item.products[0].product_name}
					/>
				</div>
			)}

			<div
				className={styles.notification__name}
				title={
					type === 'commitment'
						? item.products[0].product_name
						: item.item_name
				}
			>
				{t('notification_item_discount_available')}
				{' (' + item.item_name + ') '}
				{type === 'commitment' && item.products[0].product_name}
				{type === 'offer' && item.item_name}
			</div>

			{type === 'commitment' && (
				<div className={styles.notification__price}>
					<div className={styles['notification__open-quantity']}>
						{t('notification_item_open_quantity')}:{' '}
						<span>{item.products[0]?.open_qty}</span>
					</div>
					<div className={styles['notification__commited-quantity']}>
						{t('notification_item_commited_quantity')}:{' '}
						<span>{item.products[0]?.commited_qty}</span>
					</div>
				</div>
			)}

			{type === 'offer' && !!item?.calculated_amount && (
				<PriceView
					className={styles.notification__price}
					style={{ fontWeight: 400 }}
				>
					{formatNumber2Price(item.calculated_amount)}
				</PriceView>
			)}

			{type === 'commitment' && (
				<div className={styles.notification__enddate}>
					{t('notification_item_end_date')}:{' '}
					<span>{item.end_date}</span>
				</div>
			)}
			<Button disabled={item.is_valid == false} primary>
				{t('details_typo')}
			</Button>
		</div>
	);
};
